/**
 * AreaList
 */

import React from 'react';
import { AreaPageLink } from 'types/global';

import AreaStatusLink from 'components/AreaStatusLink';

interface Props {
	/** AreaList heading */
	heading: string;

	/** Valid until text */
	validUntilText: string;

	/** Valid to date */
	validToDate?: string;

	/** Array of area page links */
	areaPageLinks: AreaPageLink[];
}

/** AreaList component listing area pages. */
const AreaList: React.FC<Props> = ({
	heading,
	validUntilText,
	validToDate,
	areaPageLinks,
}) => {
	return (
		<section
			aria-labelledby="area-list-heading"
			className="w-full lg:w-auto p-8 lg:p-0 ml-0 mt-8 lg:mt-0"
		>
			<h2
				id="area-list-heading"
				className="text-h3 font-bold lg:text-h2 text-black"
			>
				{heading}
			</h2>

			<div className="flex items-center mb-2">
				<p className="text-h4 font-medium text-orange mr-6">{validUntilText}</p>
				<p className="text-h4 text-darker">{validToDate}</p>
			</div>

			{areaPageLinks && (
				<ul>
					{areaPageLinks.map((link: any, index: number) => (
						<AreaStatusLink {...link} key={index} index={index} />
					))}
				</ul>
			)}
		</section>
	);
};

export default AreaList;
