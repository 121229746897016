import clsx from 'clsx';
import Icon from 'components/Icon';
import React from 'react';
import { FaMountain, FaRegCompass } from 'react-icons/fa';
import {
	MdClose,
	MdInfoOutline,
	MdMyLocation,
	MdRefresh,
	MdZoomOutMap,
} from 'react-icons/md';
import { RiShapeLine } from 'react-icons/ri';
import { LegendInfoIcon } from 'types/mapTypes/MapModel';

export interface BaseCustomControlProps {
	handleControl(): void;
	title?: string;
	icon: LegendInfoIcon;
	className?: string;
	labelPosition: 'left' | 'top' | 'top-right' | 'right';
	active: boolean;
}

const BaseCustomControl: React.FC<BaseCustomControlProps> = ({
	title,
	handleControl,
	icon,
	className = '',
	labelPosition,
	active,
}) => {
	const controlTitle = `control-${title?.replace(/ /g, '')}-title`;

	function getButtonIcon(iconName: typeof icon) {
		switch (iconName) {
			case 'zoomOut':
				return <Icon aria-hidden icon="minus" size={1} />;

			case 'zoomIn':
				return <Icon aria-hidden icon="plus" size={1} />;

			case 'myPosition':
				return <Icon aria-hidden icon="crosshairs" size={77} />;

			case 'decliningScale':
				return <Icon aria-hidden icon="angle" size={77} />;

			case 'reportedAvalanches':
				return <Icon aria-hidden icon="mountain" size={77} />;

			case 'slopeDirection':
				return <Icon aria-hidden icon="compass" size={77} />;

			case 'avalancheScale':
				return <Icon aria-hidden icon="borderOuter" size={77} />;

			case 'legend':
				return (
					<>
						<Icon aria-hidden icon="cricleInfo" size={1}></Icon>
						<span className="text-small ml-2">{title}</span>
					</>
				);

			case 'reset':
				return <Icon aria-hidden icon="bin" size={77} />;

			default:
				break;
		}
	}

	return (
		<div
			className={clsx(
				className,
				'ol-unselectable ol-control',
				`${
					title?.toLocaleLowerCase() === 'nollställ' ? 'mb-4' : 'mb-4 sm:mb-2'
				}`
			)}
		>
			<button
				onClick={handleControl}
				type="button"
				aria-labelledby={controlTitle}
				className={clsx('control-button group', active && 'ol-control-active')}
			>
				{icon && icon !== 'legend' && (
					<div
						className={clsx(
							'tool-tip',
							labelPosition === 'top' && 'h-tool-tip',
							labelPosition === 'left' && 'v-tool-tip',
							labelPosition === 'top-right' && 'htr-tool-tip',
							labelPosition === 'right' && 'r-tool-tip',
							'transition ease-in-out duration-100 transform',
							'translate-y-1 group-hover:-translate-y-1',
							'invisible group-hover:visible'
						)}
					>
						<span id={controlTitle}>{title}</span>
					</div>
				)}
				{getButtonIcon(icon)}
			</button>
		</div>
	);
};

export default BaseCustomControl;
