import { ReactComponent as arrow } from './icon-arrow.svg';
import { ReactComponent as chevron } from './icon-chevron.svg';
import { ReactComponent as chevronSolid } from './icon-chevron-solid.svg';
import { ReactComponent as declineScale } from './icon-decline-scale.svg';
import { ReactComponent as glasses } from './icon-glasses.svg';
import { ReactComponent as marker } from './icon-marker.svg';
import { ReactComponent as menu } from './icon-menu.svg';
import { ReactComponent as plus } from './icon-plus.svg';
import { ReactComponent as search } from './icon-search.svg';
import { ReactComponent as snowflake } from './icon-snowflake.svg';
import { ReactComponent as angle } from './icon-angle.svg';
import { ReactComponent as borderOuter } from './icon-border-outer.svg';
import { ReactComponent as calendar } from './icon-calendar.svg';
import { ReactComponent as chevrons } from './icon-chevrons.svg';
import { ReactComponent as circleExclamation } from './icon-circle-exclamation.svg';
import { ReactComponent as compass } from './icon-compass.svg';
import { ReactComponent as crosshairs } from './icon-crosshairs.svg';
import { ReactComponent as minus } from './icon-minus.svg';
import { ReactComponent as mountain } from './icon-mountain.svg';
import { ReactComponent as remove } from './icon-remove.svg';
import { ReactComponent as snowboarding } from './icon-snowboarding.svg';
import { ReactComponent as suitcase } from './icon-suitcase.svg';
import { ReactComponent as bars } from './icon-bars.svg';
import { ReactComponent as reset } from './icon-reset.svg';
import { ReactComponent as iconWarning } from './icon-warning.svg';
import { ReactComponent as bin } from './icon-bin.svg';
import { ReactComponent as svFlag } from './icon-sv-flag.svg';
import { ReactComponent as enFlag } from './icon-en-flag.svg';
import { ReactComponent as info } from './icon-info.svg';
import { ReactComponent as list } from './icon-list.svg';
import { ReactComponent as map } from './icon-map.svg';
import { ReactComponent as cricleInfo } from './icon-circle-info.svg';

export interface IconList {
	/** The icon to be shown */
	icon:
		| 'chevron'
		| 'chevronSolid'
		| 'arrow'
		| 'plus'
		| 'search'
		| 'menu'
		| 'glasses'
		| 'marker'
		| 'snowflake'
		| 'declineScale'
		| 'angle'
		| 'borderOuter'
		| 'calendar'
		| 'chevrons'
		| 'circleExclamation'
		| 'compass'
		| 'crosshairs'
		| 'minus'
		| 'mountain'
		| 'remove'
		| 'snowboarding'
		| 'suitcase'
		| 'bars'
		| 'reset'
		| 'iconWarning'
		| 'bin'
		| 'svFlag'
		| 'enFlag'
		| 'info'
		| 'list'
		| 'map'
		| 'cricleInfo';
}

export default {
	chevron,
	chevronSolid,
	arrow,
	plus,
	search,
	menu,
	glasses,
	marker,
	snowflake,
	declineScale,
	angle,
	borderOuter,
	calendar,
	chevrons,
	circleExclamation,
	compass,
	crosshairs,
	minus,
	mountain,
	remove,
	snowboarding,
	suitcase,
	bars,
	reset,
	iconWarning,
	bin,
	svFlag,
	enFlag,
	info,
	list,
	map,
	cricleInfo,
};
