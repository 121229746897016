/**
 * DesktopAreaPage
 */

import React, { useState } from 'react';
import Map from 'components/Map';
import { MapModel } from 'types/epi';
import { Forecast as ForecastType } from 'types/pageTypes/area_page';
import MoreInformationBlock from 'components/MoreInformationBlock';

import Forecast from 'components/Forecast';
import { MoreInformationBlockModel } from 'types/blocks';
import { Link as LinkCustom } from 'types/global';
import AreaHeader from 'pages/AreaPage/components/AreaHeader';

interface Props {
	/** Description of this property */
	areaId: number;
	map: MapModel;
	forecast: ForecastType;
	forecastDateText: string;
	seePreviousForecastText: string;
	selectedDate: string;
	moreInformationBlock: MoreInformationBlockModel | undefined;

	/* area header props */
	heading: string;
	areaName: string;
	historical: {
		isHistorical: boolean;
		content: string;
		link: Omit<LinkCustom, 'target' | 'active' | 'newTab'>;
	};
	previewControls?: {
		previewButton: {
			url: string;
			text: string;
		};
		nextButton: {
			url: string;
			text: string;
		};
		previousButton: {
			url: string;
			text: string;
		};
	};
	avalancheHazardText: string;
	avalancheScaleLink: LinkCustom;
	skipMap?: string;
}

/** Main description for this component. */
const DesktopAreaPage: React.FC<Props> = ({
	areaId,
	map,
	forecast,
	forecastDateText,
	seePreviousForecastText,
	selectedDate,
	moreInformationBlock,
	heading,
	areaName,
	historical,
	previewControls,
	avalancheHazardText,
	avalancheScaleLink,
	skipMap,
}) => {
	return (
		<div className="main-w-container lg:mt-16">
			<AreaHeader
				heading={heading}
				areaName={areaName}
				forecast={forecast}
				historical={historical}
				previewControls={previewControls}
				avalancheHazardText={avalancheHazardText}
				avalancheScaleLink={avalancheScaleLink}
				isMobile={false}
			/>
			<div className="flex justify-center lg:justify-between h-auto lg:mx-auto mt-4 sm:mt-8 mb-0 sm:mb-12 flex-wrap md:flex-nowrap px-0">
				<div className="flex flex-col">
					{skipMap && (
						<a className="skipToContent" href="#area-forecast">
							{skipMap}
						</a>
					)}
					<Map {...map} areaId={areaId} isAreaPage={true} />
				</div>
				<Forecast
					forecast={forecast}
					forecastDateText={forecastDateText}
					seePreviousForecastText={seePreviousForecastText}
					selectedDate={selectedDate}
				/>
			</div>
			{moreInformationBlock && (
				<MoreInformationBlock {...moreInformationBlock} linkSize={'large'} />
			)}
		</div>
	);
};

export default DesktopAreaPage;
