/**
 * Forecast
 */
import React from 'react';
import { Forecast as ForecastType } from 'types/pageTypes/area_page';

import RichText from 'components/RichText';
import { Accordion, AccordionItem } from 'components/Accordion';
import Problem from 'pages/AreaPage/components/Problem';
import Link from 'components/Link';
import Image from 'components/Image';
import DatePickerInput from 'components/DatePickerInput';

interface Props {
	/** Description of this property */
	forecast: ForecastType;
	forecastDateText: string;
	seePreviousForecastText: string;
	selectedDate: string;
}

/** Main description for this component. */
const Forecast: React.FC<Props> = ({
	forecast,
	forecastDateText,
	seePreviousForecastText,
	selectedDate,
}) => {
	const {
		trend,
		avalancheProblem,
		assessmentHeading,
		assessmentContent,
		publishedDate,
		snowInfo,
		weatherInfo,
	} = forecast || {};

	const { trendLabel, trendImage, trendDate, trendContent, trendHeading } =
		trend || {};
	return (
		<section id="area-forecast" className="w-full lg:w-[460px] lg:ml-2 xl:ml-0">
			<div className="py-4 md:pb-4 md:pt-0">
				{assessmentHeading && (
					<h2
						id="area-page-assessment-title"
						className="text-lg font-standard leading-6 font-semibold my-4 md:mb-4 md:mt-0"
					>
						{assessmentHeading}
					</h2>
				)}

				{assessmentContent && (
					<RichText>
						<div
							dangerouslySetInnerHTML={{
								__html: assessmentContent,
							}}
						/>
					</RichText>
				)}
			</div>

			<div className="h-auto">
				<Accordion>
					{avalancheProblem &&
						avalancheProblem.problems.length > 0 &&
						avalancheProblem.problems.map(
							(
								{
									heading,
									symbol,
									description,
									direction,
									altitude,
									position,
									information,
								},
								index
							) => (
								<AccordionItem
									title={heading}
									subtitle={description.title}
									image={symbol.image}
									key={index}
								>
									<Problem
										description={description}
										direction={direction}
										altitude={altitude}
										information={information}
										position={position}
										//isMobile={isMobile}
									/>
								</AccordionItem>
							)
						)}

					{snowInfo && snowInfo.heading && (
						<AccordionItem title={snowInfo.heading}>
							<RichText className="mb-4">
								<div
									dangerouslySetInnerHTML={{
										__html: snowInfo.content,
									}}
								/>
							</RichText>
							{snowInfo.button && (
								<Link
									to={snowInfo.button.href}
									className="text-link underline hover:no-underline mb-4 block"
									fullReload
								>
									{snowInfo.button.text}
								</Link>
							)}
						</AccordionItem>
					)}

					{weatherInfo && weatherInfo.heading && (
						<AccordionItem title={weatherInfo.heading}>
							<RichText className="mb-4">
								<div
									dangerouslySetInnerHTML={{
										__html: weatherInfo.content,
									}}
								/>
							</RichText>
							{weatherInfo.button && (
								<Link
									to={weatherInfo.button.href}
									className="text-link underline hover:no-underline mb-4 block"
									fullReload
								>
									{weatherInfo.button.text}
								</Link>
							)}
						</AccordionItem>
					)}
				</Accordion>

				{trendHeading && trendLabel && (
					<div className="border-grey-light border-t-2 border-b-2 sm:mb-1 trend-heading-p">
						<div className="flex">
							<Image
								src={trendImage.src}
								alt={trendImage.alt}
								className="w-24 h-24 -ml-5 -mt-4"
							/>
							<div className="flex flex-col text-left ml-2 sm:ml-5">
								<h2
									id="area-page-how-you-read-an-avalanche-forecast"
									className="text-grey-darker text-h5"
								>
									{trendHeading}
								</h2>

								{trendLabel && (
									<p className="text-h4 font-bold text-orange">{trendLabel}</p>
								)}
								<p className="font-semibold mb-2">
									{forecastDateText} {trendDate}
								</p>
							</div>
						</div>

						{trendContent && (
							<div className="mt-4">
								<RichText>
									<div
										dangerouslySetInnerHTML={{
											__html: trendContent,
										}}
									/>
								</RichText>
							</div>
						)}
					</div>
				)}
			</div>
			<div className="mt-4 border-grey-light border-b-2 pb-4">
				{publishedDate && (
					<>
						<p className="text-lg font-bold mb-4">{seePreviousForecastText}</p>
						<DatePickerInput
							publishedDate={selectedDate}
							seePreviousForecastText={seePreviousForecastText}
						/>
					</>
				)}
			</div>
		</section>
	);
};

export default Forecast;
