/**
 * AreaPage
 */

import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { useMediaQuery } from 'react-responsive';
import { AreaPageModel } from 'types/pages';
import Icon from 'components/Icon';
import Link from 'components/Link';
import NoContent from 'components/NoContent';
import DesktopAreaPage from 'components/DesktopAreaPage';
import MobileAreaPage from 'components/MobileAreaPage';

/** This page contains the full description and information of a Forecast */
const AreaPage: React.FC<AreaPageModel> = ({
	forecast,
	heading,
	selectedDate,
	todayText,
	seePreviousForecastText,
	avalancheHazardText,
	forecastDateText,
	datepicker,
	historical,
	missingForecast,
	map,
	areaName,
	previewControls,
	moreInformationBlock,
	areaId,
	avalancheScaleLink,
	mobileMapLabel,
	skipMap,
}) => {
	const isMobileOrTablet = useMediaQuery({
		maxWidth: 1023,
	});

	const [activeTab, setActiveTab] = React.useState<'map' | 'area'>('area');
	const handleTapTab = (tab: 'map' | 'area') => {
		setActiveTab(tab);
	};
	/**
	 *
		if there is not forecast (usually in summer) or is null
	* */

	if (!forecast) {
		return (
			<>
				<div
					className={clsx(
						'my-8 py-4 border-b-2 border-gray-300 flex justify-between mx-5 lg:mx-0'
					)}
				>
					<div className="left-side">
						<span className="text-h4 font-medium leading-5 text-orange font-standard mb-3">
							{heading}
						</span>
						<h1
							id="area-name"
							className="text-2.5xl font-standard leading-8 font-medium"
						>
							{areaName}
						</h1>
						{historical?.isHistorical && (
							<p className="flex items-center w-128 rounded-md my-6 p-3 text-risk-4 border-red-500 border-1">
								<Icon size={1} icon="iconWarning" className="mr-4" />
								{historical.content}{' '}
								<Link
									href={historical.link.url}
									className="underline text-black mx-4"
									fullReload
								>
									{historical.link.text}
								</Link>
								<Icon icon="chevron" size={1} direction="left" />
							</p>
						)}
					</div>
				</div>
				<div className="flex main-w-container xl:justify-between justify-center h-auto sm:h-196 px-8 md-px-0 mt-16 mb-0 flex-wrap sm:flex-nowrap">
					<div className="max-w-md w-87">
						<NoContent
							title={missingForecast.title}
							message={missingForecast.text}
						/>
					</div>
				</div>
			</>
		);
	}

	return (
		<>
			{isMobileOrTablet ? (
				<MobileAreaPage
					areaId={areaId}
					map={map}
					forecast={forecast}
					forecastDateText={forecastDateText}
					seePreviousForecastText={seePreviousForecastText}
					selectedDate={selectedDate}
					moreInformationBlock={moreInformationBlock}
					activeTab={activeTab}
					heading={heading}
					areaName={areaName}
					historical={historical}
					previewControls={previewControls}
					avalancheHazardText={avalancheHazardText}
					avalancheScaleLink={avalancheScaleLink}
					mobileMapLabel={mobileMapLabel}
				/>
			) : (
				<DesktopAreaPage
					areaId={areaId}
					map={map}
					forecast={forecast}
					forecastDateText={forecastDateText}
					seePreviousForecastText={seePreviousForecastText}
					selectedDate={selectedDate}
					moreInformationBlock={moreInformationBlock}
					heading={heading}
					areaName={areaName}
					historical={historical}
					previewControls={previewControls}
					avalancheHazardText={avalancheHazardText}
					avalancheScaleLink={avalancheScaleLink}
					skipMap={skipMap}
				/>
			)}
		</>
	);
};

export default AreaPage;
