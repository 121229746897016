/**
 * DesktopStartPage
 */

import React from 'react';
import Map from 'components/Map';
import AreaList from 'components/AreaList';
import { AreaPageLink } from 'types/global';
import { MapModel } from 'types/epi';

export interface DesktopStartPageProps {
	areaPageLinksArea: {
		heading: string;
		validUntilText: string;
		validToDate?: string;
		areaPageLinks: Array<AreaPageLink>;
	};
	map: MapModel;
	skipMap: string;
}

/** Main description for this component. */
const DesktopStartPage: React.FC<DesktopStartPageProps> = ({
	areaPageLinksArea,
	map,
	skipMap,
}) => {
	return (
		<div className="flex main-w-container justify-center lg:justify-between px-8 md-px-0 mt-16 mb-0 gap-8">
			<div className="w-full">
				{skipMap && (
					<a className="skipToContent" href="#area-list-heading">
						{skipMap}
					</a>
				)}
				<Map {...map} />
			</div>
			<AreaList {...areaPageLinksArea} />
		</div>
	);
};

export default DesktopStartPage;
