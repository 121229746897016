/**
 * AreaStatusLink
 */

import React from 'react';
import Icon from 'components/Icon';
import Link from 'components/Link';

interface Props {
	/** The url to the area page */
	url: string;

	/** Risk icon image */
	riskImage: any;

	/** Risk text */
	riskText: string;

	/** Area name for stated risk */
	areaName: string;

	/** Areas name for popular destination */
	areas: string;

	/** Area index for id */
	index: number;
}

const AreaStatusLink: React.FC<Props> = ({
	url,
	riskImage,
	riskText,
	areaName,
	areas,
	index,
}) => (
	<li id={areaName} className="area-status-li">
		<Link href={url} className="flex justify-between items-center group">
			<div className="flex">
				<img
					src={riskImage.src}
					alt={riskImage.alt ? riskImage.alt : ''}
					aria-hidden="true"
					className="w-16 h-16"
				/>

				<div className="flex flex-col justify-center ml-2 py-3">
					<h3
						id={`area-status-link-${index}`}
						className="order-2 text-h5 font-semibold group-hover:underline"
					>
						{areaName}
					</h3>
					<p className="order-1 text-base font-bold text-orange">{riskText}</p>
					<span className="text-sm order-3">{areas}</span>
				</div>
			</div>

			<Icon size={1} icon="chevron" direction="left" className="mr-4 lg:mr-5" />
		</Link>
	</li>
);

export default AreaStatusLink;
