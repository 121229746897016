import clsx from 'clsx';
import Icon from 'components/Icon';
import Image from 'components/Image';
import MapContext from 'components/Map/MapContext';
import { MapBrowserEvent, Overlay } from 'ol';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import VectorLayer from 'ol/layer/Vector';
import { Vector } from 'ol/source';
import { Circle, Style } from 'ol/style';
import React, { useCallback, useContext, useEffect, useRef } from 'react';
import { useMediaQuery } from 'react-responsive';
import { MapArea } from 'types/mapTypes/MapModel';

interface Props {
	areaForecasts: MapArea[];
	zoomLevel: number;
}

const ForecastLayer: React.FC<Props> = ({ areaForecasts = [], zoomLevel }) => {
	const { map } = useContext(MapContext);

	// This will contain the ref's we use to set the Markers
	// At mount, it will generate a list of empty references
	const forecastRefs = useRef<Array<HTMLAnchorElement | null>>([]);

	/**
	 * Will generate a list of (existing/new) refs which will either be empty or not depending on whether the UI was rendered.
	 * If the UI was rendered it should contain a list of empty references, else a list of populated element refs
	 * @see {@link https://stackoverflow.com/a/56063129/11677503 answer by Olivier Boissé}
	 * */
	const initForecastRefs = useCallback(() => {
		forecastRefs.current = forecastRefs?.current?.slice(
			0,
			areaForecasts.length
		);
	}, [areaForecasts]);

	const isMobileOrTablet = useMediaQuery({
		maxWidth: 1023,
	});
	const isSmallMobile = useMediaQuery({
		maxWidth: 380,
	});

	const getMinYDistance = (isMobileOrTablet: boolean, zoomLevel: number) => {
		const ranges = {
			mobile: [
				{ min: 4, max: 4.7, distance: isSmallMobile ? 300000 : 220000 },
				{ min: 4.7, max: 4.9, distance: 185000 },
				{ min: 4.9, max: 5.2, distance: 160000 },
				{ min: 5.2, max: 5.4, distance: 140000 },
				{ min: 5.3, max: 5.6, distance: 120000 },
				{ min: 5.6, max: 6, distance: 100000 },
			],
			desktop: [
				{ min: 4, max: 4.7, distance: 180000 },
				{ min: 4.7, max: 5, distance: 185000 },
				{ min: 5, max: 5.2, distance: 150000 },
				{ min: 5.2, max: 5.4, distance: 130000 },
				{ min: 5.4, max: 5.6, distance: 110000 },
				{ min: 5.6, max: 6, distance: 100000 },
			],
		};

		const selectedRanges = isMobileOrTablet ? ranges.mobile : ranges.desktop;

		for (const range of selectedRanges) {
			if (zoomLevel >= range.min && zoomLevel <= range.max) {
				return range.distance;
			}
		}
		return 200000;
	};

	/**below config values are used to fix overlapping of forecast areas */

	const Y_OFFSET = 10000; // we use this value to increment distance in y-axis while overlapping
	const Y_ZOOM_LEVEL = 6; //we use this value to determine whether to adjust overlapping
	let existingCoordinates: Point[] = []; // Track placed Point geometries

	/**method to update the y-coordinates to avoid overlapping */
	const adjustYCoordinate = (
		newCoordinate: Point,
		existingCoordinates: Point[],
		zoom: number
	): Point => {
		let [newX, newY] = newCoordinate.getCoordinates();
		let adjustmentFactor = 0;
		let minDistance = getMinYDistance(isMobileOrTablet, zoom);

		// Check for overlap in Y-coordinates
		while (
			existingCoordinates.some(
				(existingPoint) =>
					Math.abs(newY - existingPoint.getCoordinates()[1]) < minDistance
			)
		) {
			adjustmentFactor += 1;
			newY = newCoordinate.getCoordinates()[1] + Y_OFFSET * adjustmentFactor; // Increment Y coordinate
		}

		// Create a new Point with adjusted Y position
		const adjustedPoint = new Point([newX, newY]);
		existingCoordinates.push(adjustedPoint);

		return adjustedPoint;
	};

	useEffect(initForecastRefs, [areaForecasts]);
	useEffect(() => {
		function addHoverEffect(e: MapBrowserEvent<UIEvent>) {
			if (e.dragging) return;

			const pixel = map.getEventPixel(e.originalEvent);
			const hit = map.hasFeatureAtPixel(pixel);

			map.getTargetElement().style.cursor = hit ? 'pointer' : '';
		}
		function setZoomDependentPosition() {
			const currentZoom = map.getView().getZoom() ?? zoomLevel;
			existingCoordinates = [];
			areaForecasts.forEach((area, areaIndex) => {
				const markerEl = forecastRefs.current[areaIndex];
				if (!markerEl) return;

				const originalCoordinates = new Point(area.coordinates);
				const adjustedPoint = adjustYCoordinate(
					originalCoordinates,
					existingCoordinates,
					currentZoom
				);

				// Access the overlay by ID to update its position based on zoom level
				const overlay = map.getOverlayById('marker' + areaIndex);
				if (overlay) {
					overlay.setPosition(
						currentZoom && currentZoom < Y_ZOOM_LEVEL
							? adjustedPoint.getCoordinates()
							: area.coordinates
					);
				}
			});
		}

		if (!map) return;

		const layerSource = new Vector({});

		areaForecasts.forEach((area, areaIndex) => {
			const markerEl = forecastRefs.current[areaIndex];

			if (markerEl == null) return;

			const originalCoordinates = new Point(area.coordinates); // Create a Point from coordinates

			// Define marker position based on zoom level
			const position =
				zoomLevel && zoomLevel < Y_ZOOM_LEVEL
					? adjustYCoordinate(
							originalCoordinates,
							existingCoordinates,
							zoomLevel
					  )?.getCoordinates()
					: area.coordinates;

			var marker = new Overlay({
				id: 'marker' + areaIndex,
				position,
				offset: [5, -17],
				element: markerEl,
				stopEvent: false,
			});

			map.addOverlay(marker);

			const circleFeature = new Feature({
				geometry: new Point(area.coordinates),
				name: area.name,
			});

			circleFeature.setStyle(
				new Style({
					image: new Circle({
						radius: 15,
					}),
				})
			);

			layerSource.addFeatures([circleFeature]);
		});

		const vectorLayer = new VectorLayer({
			source: layerSource,
			zIndex: 22,
		});

		// Adds Layer with Area Markers
		map.addLayer(vectorLayer);

		//To add hover effect on Area Markers
		map.on('pointermove', addHoverEffect);

		// Attach listener to 'moveend' on the map
		map.on('moveend', setZoomDependentPosition);

		return () => {
			if (map) {
				map.removeLayer(vectorLayer);
				map.un('pointermove', addHoverEffect);
				map.un('moveend', setZoomDependentPosition);
			}
		};
	}, [map, areaForecasts, forecastRefs.current]);

	return (
		<div
			className="bg-white group"
			style={{
				boxShadow: '2px 3px 6px 0px rgba(0, 0, 0, 0.2)',
			}}
		>
			{areaForecasts &&
				areaForecasts.map((area, areaIndex) => {
					return (
						<a
							key={areaIndex}
							// Use Callback Ref here to make sure that ForecastLayer Component is refreshed
							// This sets our list of empty refs (forecastRefs) with the elements DOM Node
							// Refer Line 22 for more.
							ref={(el) => (forecastRefs.current[areaIndex] = el)}
							id={`marker${areaIndex}`}
							className={clsx(
								'relative',
								'w-full',
								'rounded-md border border-grey-border',
								'group',
								'bg-white',
								'hover:underline',
								'flex'
							)}
							href={area.url}
						>
							<div
								className={clsx(
									'bg-white rounded-md',
									'h-8',
									'p-0 m-0',
									'transition ease-in-out duration-75',
									'bottom-0',
									'border-l border-solid'
								)}
							>
								<Image
									src={area.icon.src}
									alt={area.icon.alt ? area.icon.alt : ''}
									width={32}
									height={32}
								/>
							</div>
							<div
								className={clsx(
									'h-8 pr-2',
									'text-small font-semibold no-underline leading-8',
									'whitespace-nowrap'
								)}
							>
								{area.name}
								<span className="font-bold">
									<Icon size={99} icon="chevronSolid" direction="right" />
								</span>
							</div>
						</a>
					);
				})}
		</div>
	);
};

export default ForecastLayer;
