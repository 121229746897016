/**
 * Card Link List
 */

import React from 'react';
import { Card } from 'types/global';
import CardLink from 'components/CardLink/CardLink';

export interface LinkProps {
	/** Card Link heading */
	heading: string;

	puffs: Card[];

	isMobileOrTablet?: 'small' | 'large';
}

/** Card link list used in start page */
const CardLinkList: React.FC<LinkProps> = ({
	heading,
	puffs,
	isMobileOrTablet,
}) => (
	<div className="p-8 pb-0 lg:px-0 lg:pb-8" id="puffs-block">
		<h2
			id="list-block-heading"
			className="text-h3 lg:text-h2 font-bold mt-4 mb-4 md:mb-6"
		>
			{heading}
		</h2>
		<ul className="flex flex-wrap md:grid md:gap-8 block-grid w-full">
			{/* Flexbox layout */}
			{puffs.map((puff) => (
				<li key={puff.id} className="w-full">
					<CardLink
						size={isMobileOrTablet}
						id={puff.id}
						url={puff.url}
						image={puff.image}
						heading={puff.heading}
					/>
				</li>
			))}
		</ul>
	</div>
);

export default CardLinkList;
