/**
 * TabButton
 */

import React from 'react';
import clsx from 'clsx';

interface Props {
	/** Description of this property */
	id: string;
	label?: string;
	active: boolean;
	children: React.ReactNode;
	onClick?: () => void;
	onKeyDown?: (event: React.KeyboardEvent<HTMLButtonElement>) => void;
	onFocus?: () => void;
	tabIndex?: number;
	ariaControls?: string;
}

const TabButton = React.forwardRef<HTMLButtonElement, Props>(
	(
		{
			id,
			label,
			active,
			children,
			onClick,
			onKeyDown,
			onFocus,
			tabIndex,
			ariaControls,
		},
		ref
	) => (
		<button
			id={id}
			className={clsx(
				'w-2/4 h-[57px] flex justify-center items-center text-base font-semibold',
				active ? 'bg-white' : 'bg-grey-light'
			)}
			onClick={onClick}
			onKeyDown={onKeyDown}
			onFocus={onFocus}
			tabIndex={tabIndex}
			role="tab"
			type="button"
			aria-controls={ariaControls}
			ref={ref}
		>
			<span
				className={clsx(
					active ? 'border-b-2 border-orange pb-1' : 'border-none'
				)}
			>
				{children}
				{label}
			</span>
		</button>
	)
);

export default TabButton;
