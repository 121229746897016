import React from 'react';
import { TableRowsBlock } from 'types/blocks';
import { IImage } from 'types/global';
import { Accordion, AccordionItem } from 'components/Accordion';
import RichText from 'components/RichText';
 
interface Props {
    /** Table Headings */
    tableHeadTexts: string[];
 
    /** Table Rows */
    tableRows: Array<TableRowsBlock>;
 
    /** Table Name */
    heading?: string;
 
    /** Table Foot notes */
    tableFootNotes?: string;
 
    /** Lavin logo */
    image: IImage;
 
    className?: string;
}
 
const MobileRiskTable: React.FC<Props> = ({
    tableHeadTexts,
    tableRows,
    heading,
    tableFootNotes,
    image,
    className,
}) => {
    const [
        scaleImage,
        riskLevel,
        recommendation,
        probability,
        sizeAndSpread,
    ] = tableHeadTexts;
 
    // Create an empty array to store the texts
    const riskLevelText: string[] = [];
    const recommendationText: string[] = [];
    const probabilityText: string[] = [];
    const sizeAndSpreadText: string[] = [];
 
    // Loop through the tableRows array and extract the texts
    for (const row of tableRows) {
        const [pos1, pos2, pos3, pos4] = row.cells.slice(1, 5); // Get cells at positions 1, 2, 3, and 4
 
        const text1 = pos1?.text;
        const text2 = pos2?.text;
        const text3 = pos3?.text;
        const text4 = pos4?.text;
 
        if (text1) {
            riskLevelText.push(text1);
        }
        if (text2) {
            recommendationText.push(text2);
        }
        if (text3) {
            probabilityText.push(text3);
        }
        if (text4) {
            sizeAndSpreadText.push(text4);
        }
    }
 
    return (
        <div className={className}>
            <h2 className="text-h3 lg:text-h2 font-bold mb-2" aria-label={heading}>
                {heading}
            </h2>
            <Accordion>
                <div className="flex">
                    <span className="py-2 font-bold text-sm mr-6" aria-label={scaleImage}>
                        {scaleImage}
                    </span>
                    <span className="py-2 font-bold text-sm" aria-label={riskLevel}>
                        {riskLevel}
                    </span>
                </div>
 
                {tableRows && tableRows.map((row, i) => {
                    {
                        return row.cells.map((cell, cellIndex) => {
                            if (cellIndex === 0) {
                                const { backgroundImage, backgroundColor, textColor } = cell;
 
                            return (
                                <AccordionItem
                                    title={riskLevelText[i]}
                                    image={backgroundImage}
                                    backgroundColor={backgroundColor}
                                    textColor={textColor}
                                    key={cellIndex}
                                    isRiskScale
                                    aria-label={riskLevelText[i]}
                                >
                                    <span
                                        className="py-2 font-bold text-sm"
                                        aria-label={recommendation}
                                    >
                                        {recommendation}
                                    </span>
 
                                    <p className="py-2 h-auto" aria-label={recommendationText[i]}>
                                        {recommendationText[i]}
                                    </p>
 
                                    <span
                                        className="py-2 font-bold text-sm"
                                        aria-label={probability}
                                    >
                                        {probability}
                                    </span>
 
                                    <p className="py-2 h-auto" aria-label={probabilityText[i]}>
                                        {probabilityText[i]}
                                    </p>
 
                                    <span
                                        className="py-2 font-bold text-sm"
                                        aria-label={sizeAndSpread}
                                    >
                                        {sizeAndSpread}
                                    </span>
 
                                    <p className="py-2 h-auto" aria-label={sizeAndSpreadText[i]}>
                                        {sizeAndSpreadText[i]}
                                    </p>
                                </AccordionItem>
                            );
                        }
                        return null;
                    });
                }})}
            </Accordion>
            {tableFootNotes && (
                <RichText>
                    <div
                        className="mt-4 p-4 border-t-1 border-grey border-solid"
                        dangerouslySetInnerHTML={{
                            __html: tableFootNotes,
                        }}
                    />
                </RichText>
            )}
        </div>
    );
};
 
export default MobileRiskTable;