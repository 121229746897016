import clsx from 'clsx';
import Icon from 'components/Icon';
import { MapProvider } from 'components/Map/MapContext';
import { Map, View } from 'ol';
import { FullScreen, defaults as controlDefaults } from 'ol/control';
import Point from 'ol/geom/Point';
import { defaults as interactionDefaults } from 'ol/interaction';
import { Tile } from 'ol/layer';
import { get, transformExtent } from 'ol/proj';
import { TileWMS } from 'ol/source';
import React, { useEffect, useRef, useState } from 'react';
import { ControlMetaData } from 'types/mapTypes/MapModel';

import { mOverExpand, mOutCompress } from './fullScreenEvents';

interface Props {
	centerCoordinates: number[];
	zoomLevel: number;
	maxZoomLevel: number;
	minZoomLevel: number;
	tileUrl: string;
	fullscreen?: ControlMetaData;
	defaultFullscreen?: boolean;
	heightDevice?: number;
}

let fslabel: HTMLImageElement;
let fsLabelActive: HTMLImageElement;

const MapContainer: React.FC<Props> = ({
	centerCoordinates,
	zoomLevel,
	maxZoomLevel,
	minZoomLevel,
	children,
	tileUrl,
	fullscreen,
	defaultFullscreen = false,
	heightDevice,
}) => {
	const mapRef = useRef<HTMLDivElement>(null);
	const [map, setMap] = useState<any>(null);

	const fullScreenCss =
		fullscreen?.title === 'Fullskärm'
			? 'full-screen-btn'
			: 'full-screen-btn-en';

	useEffect(() => {
		fslabel = document.createElement('img');
		fslabel.id = 'full-screen-inactive';
		fslabel.alt = 'full-screen-inactive';
		fslabel.src = '/assets/images/icon-expand.png';

		fsLabelActive = document.createElement('img');
		fsLabelActive.id = 'full-screen-active';
		fslabel.alt = 'full-screen-active';
		fsLabelActive.src = '/assets/images/icon-compress.png';

		fslabel.addEventListener(
			'mouseover',
			() => mOverExpand(fslabel, '/assets/images/icon-expand-hover.png'),
			false
		);
		fsLabelActive.addEventListener(
			'mouseover',
			() =>
				mOverExpand(fsLabelActive, '/assets/images/icon-compress-hover.png'),
			false
		);
		fslabel.addEventListener(
			'mouseout',
			() => mOutCompress(fslabel, '/assets/images/icon-expand.png'),
			false
		);
		fsLabelActive.addEventListener(
			'mouseout',
			() => mOutCompress(fsLabelActive, '/assets/images/icon-compress.png'),
			false
		);

		return () => {
			fslabel.removeEventListener('mouseover', () => mOverExpand, false);
			fsLabelActive.removeEventListener('mouseover', () => mOverExpand, false);
			fslabel.removeEventListener('mouseout', () => mOutCompress, false);
			fsLabelActive.removeEventListener('mouseout', () => mOutCompress, false);
		};
	}, []);

	useEffect(() => {
		if (mapRef.current) {
			const controls = controlDefaults({
				attribution: false,
				zoom: false,
				rotate: false,
			});
			if (!defaultFullscreen) {
				controls.extend([
					new FullScreen({
						label: fslabel,
						labelActive: fsLabelActive,
						tipLabel: fullscreen?.title,
						className: fullScreenCss,
					}),
				]);
			}

			const mapObj = new Map({
				target: mapRef.current,
				view: new View({
					projection: get('EPSG:3857'),
					center: new Point(centerCoordinates).getCoordinates(),
					zoom: zoomLevel,
					minZoom: minZoomLevel,
					maxZoom: maxZoomLevel,
					extent: transformExtent([-15, 54, 51, 70], 'EPSG:4326', 'EPSG:3857'),
				}),
				layers: [
					new Tile({
						source: new TileWMS({
							url: tileUrl,
							params: {
								LAYERS: 'lantmateriet:lantmateriet_base',
								TILED: true,
							},
						}),
					}),
				],
				controls: controls,
				interactions: interactionDefaults({
					mouseWheelZoom: true,
					dragPan: true,
					doubleClickZoom: true,
					keyboard: true,
				}),
				maxTilesLoading: 4,
			});
			setMap(mapObj);
			return () => mapObj.setTarget(undefined);
		}
	}, [zoomLevel, maxZoomLevel, minZoomLevel, centerCoordinates, heightDevice]);

	useEffect(() => {
		if (!map) return;

		(map as Map).setView(
			new View({
				projection: get('EPSG:3857'),
				center: new Point(centerCoordinates).getCoordinates(),
				zoom: zoomLevel,
				minZoom: minZoomLevel,
				maxZoom: maxZoomLevel,
				extent: transformExtent([-15, 54, 51, 70], 'EPSG:4326', 'EPSG:3857'),
			})
		);
	}, [centerCoordinates, zoomLevel, minZoomLevel, maxZoomLevel, map]);

	const handleFullscreenChange = () => {
		if (!map) return;

		if (!document.fullscreenElement) {
			map.setView(
				new View({
					projection: get('EPSG:3857'),
					center: new Point(centerCoordinates).getCoordinates(),
					zoom: zoomLevel,
					minZoom: minZoomLevel,
					maxZoom: maxZoomLevel,
					extent: transformExtent([-15, 54, 51, 70], 'EPSG:4326', 'EPSG:3857'),
				})
			);
		}
	};
	/**atttaching full screen event */
	useEffect(() => {
		document.addEventListener('fullscreenchange', handleFullscreenChange);
		return () => {
			document.removeEventListener('fullscreenchange', handleFullscreenChange);
		};
	}, [map]);

	return (
		<MapProvider map={map}>
			<div
				id="map"
				ref={mapRef}
				className={clsx(
					'w-full',
					'transition-all ease-in-out duration-100',
					defaultFullscreen && 'full-screen-map'
				)}
			>
				{children}
			</div>
		</MapProvider>
	);
};

export default MapContainer;
